export enum FormFieldType {
  textBox = 'textBox',
  radioButton = 'radioButton',
  checkBox = 'checkBox',
  noHighlighting = 'noHighlighting',
  dropDown = 'dropDown'
}

export enum ProductPackage {
  VisionTherapy = 'VisionTherapy',
  PEC = 'PEC',
  DEPPlus = 'DEPPlus',
  ComputerVisionCarePlan = 'ComputerVisionCarePlan',
  ComputerVisionCarePlanSupplemental = 'ComputerVisionCarePlanSupplemental',
  VDT = 'VDT',
  VDTSupplemental = 'VDTSupplemental'
}

export enum DiagnosisCodeGroup {
  WellVision = 'WellVision',
  VisionTherapy = 'VisionTherapy',
  PrimaryEyeCare = 'PrimaryEyeCare',
  DiabeticEyeCarePlus = 'DiabeticCarePlus',
  ComputerVisionSyndrome = 'ComputerVisionSyndrome',
}

export function invalidFirstNameMessage(qualifier: string) {
  return `Please enter a valid ${qualifier}'s First Name`;
}

export function invalidLastNameMessage(qualifier: string) {
  return `Please enter a valid ${qualifier}'s Last Name`;
}

export function doctorsNotFoundForDateOfService(dateOfService: string) {
  return (dateOfService && dateOfService.length > 0) ? `No providers active for Date of Service ${dateOfService}.` : 'No providers active for the entered Date of Service.';
}

export function submitAuthorizationUsingLegacy(authorizationNumber: string) {
  return `Unexpected System Error, please submit using the legacy eClaim on eyefinity.com for Authorization # ${authorizationNumber}`;
}

export const ApplicationConstants = {
  labCategoryForShipsFreeItem: 'PREFERRED LABS - FRAME SHIPS FREE TO LABS',
  labCategoryForPreferredItem: 'PREFERRED LABS',
  freeShipping: 'FREE SHIPPING',
  savingsPass: 'SAVINGS PASS',
  kaleyedoscope: 'kaleyedoscope',
  kscopeProgramFAQsUrl: 'https://app-go.eyefinity.com/e/er?s=838970723&lid=1428&elq=~~eloqua..type--emailfield..syntax--recipientid..encodeFor--url~~',
  ampersandSymbol: '&',
  period: '.',
  comma: ',',
  forwardSlash: '/',
  pipeWithLeadingAndEndingSpace: ' | ',
  applicationId: 'eclaim',
  localeEnglishUS: 'en-US',
  minDate: new Date('01/02/1901'),
  errorMessageDisplayMinDate: new Date('01/01/1901'),
  twoDigitDecimalFormat: '1.2-2',
  todaysDate: new Date(),
  updateFormWithoutEmit: {emitEvent: false},
  updateFormWithOnlySelf: {onlySelf: true},
  thirtyDaysPriorToTodayDate: new Date().setDate(new Date().getDate() - 30),
  userInteractionDebounceTime: 250, // 250 ms used as a debounce time for any events that would fire more frequently due to user interaction
  addressStreetAndCityValidRegex: new RegExp('^[a-zA-Z0-9 . \\- \/\\\\,#&]+$'),
  addressStateCodeRegex: new RegExp('^[A-Za-z]+$'), // Alpha only
  stateCodeRegex: new RegExp('^(?:A[AEKLPRZ]|C[AOT]|D[CE]|FL|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[ACDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])*$'),
  mmDdYyyyDateRegex: new RegExp('^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$'),
  yyyymmddDateRegex: new RegExp('^[0-9]{4}[\/-][0-9]{1,2}[\/-][0-9]{1,2}$'),
  singleDigitMonthOrDayDateRegex: new RegExp('^(([0-9]{1,2}\/[0-9]{1})|([0-9]{1}\/[0-9]{1,2}))\/[0-9]{4}$'),
  generalizedDateRegex: new RegExp('^[\\d\/]{10}$'),
  ValidNameRegex: new RegExp('^[\'.,-]?[a-zA-Z]+[ .]?([-][a-zA-Z])?([\'., a-zA-Z])*$'),
  atLeastOneAlphaRegex: new RegExp('^.*[a-zA-Z].*$'),
  cptHcpcsCodeRegex: new RegExp('^[a-zA-Z0-9]{5}$'),
  dyeColorOtherDescriptionRegex: new RegExp('^[0-9a-zA-Z()\\-/._:% ]+$'),
  mirrorSkiCoatingOtherDescriptionRegex: new RegExp('^[0-9a-zA-Z()\\-/._: ]+$'),
  lensValueStringRegex: new RegExp('^[a-zA-Z0-9. -]+$'),
  lensFieldsPasteCharacterRegex: new RegExp(/[a-zA-Z0-9. -]/g),
  rightAndLeftBaseCurveRegex: new RegExp('^^(([0-9]|[0-3][0-9])?([.][0-9]{1,2})?$)|40([.][0]{1,2})?$'),
  anySignedOrUnsignedNumber: new RegExp(/^[+-]?[0-9]+(\.?[0-9]+)?|^[0](\.?[0]+)?$/),
  oneOrTwoDigitUnsignedNumberUpToTwoDecimalPlaces: new RegExp(/^[0-9]{1,2}(\.[0-9]{1,2})?$/),
  oneOrTwoDigitUnsignedNumberOneOptionalDecimalPlace: new RegExp(/^[0-9]{1,2}(\.[0-9])?$/),
  prescriptionDistanceAndNearEye: new RegExp(/^[0-9]{1,2}\.?[0-9]?$/),
  prescriptionEyeAxis: new RegExp(/^[0-9]{1,3}$/),
  prescriptionWrapAngleRegex: new RegExp(/^\+?\d{1,2}((\.)?\d{1,2})?$/),
  prescriptionPantoscopicTiltRegex: new RegExp(/^[+-]?\d{1,2}((\.)?\d{1,2})?$/),
  prescriptionBackVertexDistanceRegex: new RegExp(/^\d{1,2}((\.)?\d{1,2})?$/),
  prescriptionLeftRightFieldsKeypressRegex: new RegExp(/[0-9.+-]/),
  prescriptionLeftRightFieldsPasteFilterRegex: new RegExp(/[0-9.+-]/g),
  wholesaleFrameCostRegex: new RegExp(/^[1-9][0-9]*(\.[0-9]{1,2})?$/),
  memberInsurancePlanValidRegex: new RegExp(/^([a-zA-Z0-9,.' ]|[-][a-zA-Z0-9])+$/),
  domainOfUrlRegex: new RegExp(/^.*\/\/[^\/]+/),
  prescriptionWrapAngleMinimumValue: 0,
  prescriptionWrapAngleMaximumValue: 30,
  prescriptionPantoscopicTiltMinimumValue: -5,
  prescriptionPantoscopicTiltAndVertexDistanceMaximumValue: 25,
  prescriptionBackVertexDistanceMinimumValue: 5,
  oneOrTwoDigitOptionallySignedNumberOneOptionalDecimalPlace: new RegExp(/^[+-]?[0-9]{1,2}(\.[0-9])?$/),
  labSpecialInstructionOrderRegex: new RegExp('^[0-9]{8,10}$'),
  rightLeftBaseCurveAndCustomizableLensFieldsMaxLength: 5,
  decimalNumberRegex: new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/),
  maxStateCodeLength: 2,
  cityMaxLength: 25,
  addressMaxLength: 30,
  maxDollarAmountAllowed: 99999.99,
  additionalClaimInformationMaxLength: 100,
  messageMaxLength: 1000,
  queryMaxLength: 50,
  maxSubscriberSearchResult: 25,
  maxDateLength: 10,
  maxFirstNameLength: 10,
  maxLastNameLength: 18,
  maxFrameSearchResults: 100,
  maxGlassCoatingDescriptionLength: 10,
  maxLensCardDescriptionLength: 10,
  maxLabSpecialInstructionOrderLength: 10,
  oneThousand: 1000,
  twoThousand: 2000,
  modalSelectionOk: 'OK',
  modalSelectionYes: 'YES',
  modalSelectionSave: 'SAVE',
  eClaimPopupModal: 'eclaim-popup-modal',
  loadingCoverage: 'LOADING COVERAGE',
  trueString: 'true',
  falseString: 'false',
  modalSelectionLeaveForm: 'LEAVE FORM',
  noWarning: 'No Warning',
  NpiIdRegex: `^[0-9]*$`,
  invalidFirstNameMessage: invalidFirstNameMessage,
  invalidLastNameMessage: invalidLastNameMessage,
  homeAddressTypeCode: 'HM',
  unknownAddressTypeCode: 'UN',
  yesSingleCharIndicator: 'Y',
  trueSingleCharIndicator: 'T',
  noSingleCharIndicator: 'N',
  notFound: 'NOT FOUND',
  noDescriptionFound: 'No description found',
  emptyString: '',
  blankSpace: ' ',
  zeroString: '0',
  zeroStringTwoDecimalPlaces: '0.00',
  plusSign: '+',
  minusSign: '-',
  otherMirror: 'Other Mirror',
  authorizationNumber: 'authorizationNumber',
  giftCertificateNumber: 'giftCertificateNumber',
  viewingDoctorReports: 'viewingDoctorReports',
  legacyEInsurancePageURL: '/eyefinity/html/eInsurance.htm',
  efSurlCookie: 'EF_SURL',
  tefSurlCookie: 'TEF_SURL',
  enableProviderSearchTrue: 'enableProviderSearch=true',
  providerSearchExactEffectiveDate: 'providerSearchExactEffectiveDate=',
  providerSearchStartDate: 'providerSearchStartDate=',
  providerSearchEndDate: 'providerSearchEndDate=',
  staging: 'staging',
  production: 'production',
  routingPageContent: {
    cannotContinueForTheFollowingReason: 'Cannot Continue for the Following Reason',
    pleaseReturnToEInsurancePage: 'Please return to the eINSURANCE Page and try again.',
    buttonContent: 'Return to the eINSURANCE Page'
  },
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  claimsTracking: {
    // Temp values
    tempPracticeName: 'Practice Name is not available',
    tempPracticeStreet: 'Practice Street is not available',
    tempPracticeCityStateZip: 'Practice City, State and Zip are not available'
  },
  VSPIDC_ACCESS: 'VSPIDC_ACCESS',
  routing: {
    public: {
      baseRoute: 'public',
      expiredSessionPageUrl: 'public/expired-session'
    },
    secure: {
      baseRoute: 'secure',
      memberSearch: 'eInsurance/member-search',
      memberSearchPageUrl: 'secure/eInsurance/member-search',
      memberSearchBreadcrumb: 'eClaim/Member Search',
      claimsTracking: 'eInsurance/claims-tracking',
      claimsTrackingPageUrl: 'secure/eInsurance/claims-tracking',
      claimsTrackingBreadcrumb: 'eClaim/Claims Tracking',
      claimForm: 'claim-form',
      claimFormEdit: 'edit',
      claimFormEditPageUrl: 'secure/claim-form/edit',
      claimFormView: 'view',
      patientSelection: 'eInsurance/member-search/patient-selection',
      patientSelectionPageUrl: 'secure/eInsurance/member-search/patient-selection',
      patientSelectionBreadcrumb: 'eClaim/Member Search/patient-selection',
      coverageSummary: 'eInsurance/member-search/patient-selection/coverage-summary',
      coverageSummaryPageUrl: 'secure/eInsurance/member-search/patient-selection/coverage-summary',
      coverageSummaryBreadcrumb: 'eClaim/Member Search/patient-selection/Coverage Summary',
      cobCoverage: 'eInsurance/member-search/patient-selection/coverage-summary/cob-coverage',
      cobCoveragePageUrl: 'secure/eInsurance/member-search/patient-selection/coverage-summary/cob-coverage',
      cobCoverageBreadcrumb: 'eClaim/Member Search/patient-selection/Coverage Summary/COB Coverage',
      authorizationConfirmation: 'eInsurance/member-search/patient-selection/coverage-summary/authorization-confirmation',
      authorizationConfirmationBreadcrumb: 'eClaim/Member Search/patient-selection/Coverage Summary/Confirmation',
      claimManagement: 'claim-management',
      eInsurance: 'eInsurance',
      eInsurancePageUrl: 'secure/eInsurance',
      giftCertificateSubmission: 'eInsurance/gift-certificate-submission',
      giftCertificateSubmissionPageUrl: 'secure/eInsurance/gift-certificate-submission',
      giftCertificateSubmissionBreadcrumb: 'eClaim/Gift Certificate Submission',
      authTracking: 'eInsurance/auth-tracking',
      authTrackingPageUrl: 'secure/eInsurance/auth-tracking',
      authTrackingBreadCrumb: 'eClaim/Track Authorizations'
    }
  },
  api: {
    patientEncounterApi: 'patient-encounter-api',
    claimGiftCertificatesApi: 'claim-gift-certificates-api',
    consumerSearchApiWeb: 'consumer-searchapi-web',
    visionServiceRequest: 'vision-service-requestapi-web',
    consumerRetrieve: 'memberpolicy-web',
    // Per ECLAIM-103 - We want to add the 'includeActive=true' query filter to all the catalogframe-consumption-api searches.
    frameSearchPartialEndpoint: 'catalogframe-consumption-api/frames?includeInactive=true&',
    frameSearchQueryParameter: 'query=',
    frameSearchUpcParameter: 'upc=',
    frameSearchSkuParameter: 'sku=',
    catalogLensApi: 'cataloglens-consumption-api',
    consumerBusinessApi: 'consumerbusinessapi',
    consumerBusinessApiDetailHeader: 'base,consumerid,links,fehb,name,coverage,dependents',
    externalServiceLocationEndpoint: '/provider-web/externalservicelocation',
    inventoryApi: 'inventory-web',
    lensCatalogEndpoint: {
      scratchCoatings: 'scratchcoatings',
      arCoatings: 'arcoatings',
      spectacleLens: 'spectaclelens',
      lensVisionType: 'lensvisiontypes',
      lensMaterialType: 'lensmaterialtypes',
      miscellaneousServices: {
        tintColors: 'miscellaneousservices/tintcolors',
        mirrorSkiCoatings: 'miscellaneousservices/mirrorskicoatings',
        dyeTypes: 'miscellaneousservices/tinttypes',
        uvCoatings: 'miscellaneousservices/uvcoatings',
        glassCoatings: 'miscellaneousservices/glasscoatings',
        edgeCoatings: 'miscellaneousservices/edges',
        bevels: 'miscellaneousservices/bevel'
      }
    },
    contactLensApi: 'contact-lens-api',
    contactLensEndpoint: {
      materials: 'materials',
      manufacturers: 'manufacturers',
      modality: 'modality'
    },
    claimsOnlineReferenceApi: 'claims-online-reference-api',
    claimsOnlineReferenceEndpoint: {
      services: 'services',
      reasons: 'reasons'
    },
    claimSummaryApi: 'priced-claim-summary-api',
    fulfillmentApi: {
      frameDisposition: '/frameboard/disposition',
      frameDropshipOrder: '/frameboard/dropshiporder',
      frameReplacementOrder: '/frameboard/replacementorder'
    },
    fulfillmentParticipatingLabsApi: {
      participatingLabsDisposition: '/frameboard/participatinglabs'
    },
  },
  errorMessages: {
    noLensFoundMessage: 'There are no lenses found for this combination',
    memberIdAlphaNumericErrorMessage: 'Please enter a valid Full ID. Only alpha-numeric characters are accepted.',
    memberIdInvalidMemberIdErrorMessage: 'ID must contain at least one alpha character when fewer than 9 characters',
    genericApiFailMessage: 'A problem was encountered processing your request. Please try again. If you are still experiencing a problem, check back at a later time.',
    authTrackingDeleteFailMessage: 'Currently unable to delete this authorization, please check back at a later time.',
    doctorReportsFailMessage: 'Dr. Reports are currently unavailable, please check back at a later time.',
    providerWebApiFailMessage: 'Unable to retrieve the list of doctors at this practice, please try again later.',
    providerWebApiFailMessageTwo: 'Unable to retrieve the practice information, please try again later.',
    giftCertificateApiFailMessage: 'Unexpected System Error, please submit using the legacy eClaim on eyefinity.com.',
    doctorsNotFoundForDateOfService: doctorsNotFoundForDateOfService,
    invalidOrDeletedAuthorization: 'This authorization number is not valid or has been deleted.',
    retrievedAuthBelongsToOtherDoctor: 'The Authorization Number you have entered cannot be used.',
    // TODO: Update the below message by request of Kelly Greene when necessary
    failSafeLabList: 'Due to current system limitations, claim submission is only available to the displayed labs. If you are expecting to see a different lab list, you may try to submit your claim at a later time.',
    submitAuthorizationUsingLegacy: submitAuthorizationUsingLegacy,
    feedbackFormPeFailMessage: 'The feedback form is unable to be submitted at this time, please try again later.',
    showHcpcCptDescriptionErrorMessage: 'This feature is temporarily unavailable. Please use the "?" by field 24d to look up HCPC/CPT Codes.',
    gatewayTimeOutMessage: 'This action is taking longer than expected. Please check back later to verify whether the claim submitted successfully, visit the eINSURANCE page and search by authorization number.'
  },
  eligibilitySuspendMessages: {
    noMatchingNetworkFoundMessage: 'Your Practice does not participate in this patient\'s plan. For details, call VSP\'s Provider Services Support Line.',
    dateOutsideAllowedPaymentPeriodMessage: 'We\'re unable to proceed with your request.\n' +
      'The date of service exceeds the time frame allowed by this client. For details, call VSP\'s Provider Services Support Line at 800.615.1883.',
    dateOfBirthIsMissingEligibilitySuspendCustomMessage: 'A problem was encountered: The Patient\'s Date of Birth is missing. Please call VSP.',
  },
  fulfillmentApiMessage: {
    frameDropShipRequestWasSuccessfullySent: 'Frame DropShip Request Was Successfully Sent',
    frameReplacementRequestWasSuccessfullySent: 'Frame Replacement Request Was Successfully Sent'
  },
  componentIDs: {
    additionalInformation: 'additional-information-section',
    contacts: 'contacts-section',
    dateOfService: 'date-of-service-section',
    exam: 'exam-section',
    frame: 'frame-section',
    frameDetails: 'frame-details',
    lens: 'lens-section',
    facilityAndBilling: 'facility-and-billing-section',
    insured: 'insured-section',
    patient: 'patient-section',
    prescription: 'prescription-section',
    services: 'services-section',
    signatures: 'signatures-section',
    claimEdit: 'claimEdit',
    lab: 'lab-section',
    softAndHardEdits: 'soft-and-hard-edits',
    updateAllSections: 'update-all-sections'
  },
  dataServiceClientIDs: {
    consumerDataService: 'ConsumerDataService',
    eligibilityDataService: 'EligibilityDataService',
    visionServiceRequestDataService: 'VisionServiceRequestDataService',
    appDataService: 'AppDataService',
    externalServiceLocationDataService: 'ExternalServiceLocationDataService',
    lensCatalogServiceDataService: 'LensCatalogDataService',
    frameCatalogServiceDataService: 'FrameCatalogDataService',
    supplierWebDataService: 'SupplierWebDataService',
    contactLensServiceDataServices: 'ContactLensDataService',
    inventoryServiceDataServices: 'InventoryDataService',
    fulfillmentService: 'FulfillmentService'
  },
  patientEncounterAPIModelToUIFieldMapper: {
    'additionalClaimInformationHCFABox19':                                      {ids: ['#additional-information-claim-input'], fieldType: FormFieldType.textBox},
    'conditionRelatedTo.employment':                                            {ids: ['#additional-information-patients-condition-employment-group'], fieldType: FormFieldType.radioButton},
    'conditionRelatedTo.autoAccident':                                          {ids: ['#additional-information-patients-condition-auto-accident-group'], fieldType: FormFieldType.radioButton},
    'conditionRelatedTo.otherAccident':                                         {ids: ['#additional-information-patients-condition-employment-group'], fieldType: FormFieldType.radioButton},
    'dateOfService':                                                            {ids: ['#date-of-service'], fieldType: FormFieldType.textBox},
    'exam.dilation':                                                            {ids: ['#exam-dilation-performed-group'], fieldType: FormFieldType.radioButton},
    'exam.examServiceCode':                                                     {ids: ['#exam-type-group'], fieldType: FormFieldType.dropDown},
    'exam.refraction':                                                          {ids: ['#exam-refraction-performed-checkbox'], fieldType: FormFieldType.checkBox},
    'doctorSignature.indicator':                                                {ids: ['#facility-and-billing-signature-checkbox'], fieldType: FormFieldType.checkBox},
    'cms1500Diagnoses':                                                         {ids: ['#services-diagnosis-code-A-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[0].diagnosisCode':                                        {ids: ['#services-diagnosis-code-A-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[1].diagnosisCode':                                        {ids: ['#services-diagnosis-code-B-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[2].diagnosisCode':                                        {ids: ['#services-diagnosis-code-C-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[3].diagnosisCode':                                        {ids: ['#services-diagnosis-code-D-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[4].diagnosisCode':                                        {ids: ['#services-diagnosis-code-E-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[5].diagnosisCode':                                        {ids: ['#services-diagnosis-code-F-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[6].diagnosisCode':                                        {ids: ['#services-diagnosis-code-G-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[7].diagnosisCode':                                        {ids: ['#services-diagnosis-code-H-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[8].diagnosisCode':                                        {ids: ['#services-diagnosis-code-I-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[9].diagnosisCode':                                        {ids: ['#services-diagnosis-code-J-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[10].diagnosisCode':                                       {ids: ['#services-diagnosis-code-K-textbox'], fieldType: FormFieldType.textBox},
    'cms1500Diagnoses[11].diagnosisCode':                                       {ids: ['#services-diagnosis-code-L-textbox'], fieldType: FormFieldType.textBox},
    'serviceLines':                                                             {ids: ['#services-service-line-proc-link'], fieldType: FormFieldType.noHighlighting},
    'cobAuthorizationNumber':                                                   {ids: ['#insured-secondary-vsr-input'], fieldType: FormFieldType.textBox},
    'contactLens.brandName':                                                    {ids: ['#contacts-brand-dropdown'], fieldType: FormFieldType.dropDown},
    'contactLens.clManufacturer':                                               {ids: ['#contacts-manufacturer-dropdown'], fieldType: FormFieldType.dropDown},
    'contactLens.manufacturer':                                                 {ids: ['#contacts-manufacturer-dropdown'], fieldType: FormFieldType.dropDown},
    'contactLens.numberOfBoxes':                                                {ids: ['#contacts-number-of-boxes-textbox'], fieldType: FormFieldType.textBox},
    'contactLens.reasonCode':                                                   {ids: ['#contacts-reason-dropdown'], fieldType: FormFieldType.dropDown},
    'doctorAcceptAssignmentIndicator':                                          {ids: ['#facility-and-billing-accept-assignment-group'], fieldType: FormFieldType.radioButton},
    'labOrderInformation.balanceLens':                                          {ids: ['#lens-balance-lens-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.frame.brand.name':                                     {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frame.manufacturer.name':                              {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frame.name':                                           {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frame.options[0].color.name':                          {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frame.options[0].size.dbl':                            {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frame.options[0].size.eye':                            {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frame.options[0].size.temple':                         {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frame.options[0].wholeSalePrice':                      {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.frameSupplier':                                        {ids: ['#frame-title'], fieldType: FormFieldType.noHighlighting},
    'labOrderInformation.labNumber':                                            {ids: ['#lab-lab-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.labSpecialInstructions.backsideAntiReflectiveOnly':    {ids: ['#lab-special-instructions'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.labSpecialInstructions.details':                       {ids: ['#lab-special-instruction-redo-details-input'], fieldType: FormFieldType.textBox},
    'labOrderInformation.labSpecialInstructions.redoOrderNumber':               {ids: ['#labSpecialInstructionRedoOrderInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.labSpecialInstructions':                               {ids: ['#lab-special-instructions-special-measurement-input'], fieldType: FormFieldType.textBox},
    'labOrderInformation.labSpecialInstructions.type':                          {ids: ['#lens-AR-coating-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lens.externalId':                                      {ids: ['#lens-lens-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lens.materialType':                                    {ids: ['#lens-material-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lens.visionType':                                      {ids: ['#lens-vision-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensAntiReflectiveCoating.code':                       {ids: ['#lens-AR-coating-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensAntiReflectiveCoating.description':                {ids: ['#lens-AR-coating-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensBaseCurveLeft':                                    {ids: ['#lens-left-base-curve-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensBaseCurveRight':                                   {ids: ['#lens-right-base-curve-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensBevel.code':                                       {ids: ['#lens-bevel-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensEdge.code':                                        {ids: ['#lens-edge-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensGlassCoating.code':                                {ids: ['#lens-glass-coating-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensGlassCoating.description':                         {ids: ['#lens-glass-coating-description-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensMirrorSkiCoating.description':                     {ids: ['#lens-mirror-ski-coating-other-description'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensMirrorSkiCoating.code':                            {ids: ['#lens-mirror-ski-coating-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.addLeft':                             {ids: ['#prescriptionLeftEyeAddInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.addRight':                            {ids: ['#prescriptionRightEyeAddInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.axisLeft':                            {ids: ['#prescriptionLeftEyeAxisInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.axisRight':                           {ids: ['#prescriptionRightEyeAxisInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.backVertexDistance':                  {ids: ['#prescription-vertex'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.binocularPdDistant':                  {ids: ['#prescriptionRightEyeDistanceInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.binocularPdNear':                     {ids: ['#prescriptionRightEyeNearInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.cylinderLeft':                        {ids: ['#prescriptionLeftEyeCylinderInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.cylinderRight':                       {ids: ['#prescriptionRightEyeCylinderInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.horizontalPrismDirLeft':              {ids: ['#hBase-Left'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.horizontalPrismDirRight':             {ids: ['#hBase-Right'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.horizontalPrismLeft':                 {ids: ['#prescription-horizontal-prism-left-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.horizontalPrismRight':                {ids: ['#prescription-horizontal-prism-right-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.monocularPdDistantLeft':              {ids: ['#prescriptionLeftEyeDistanceInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.monocularPdDistantRight':             {ids: ['#prescriptionRightEyeDistanceInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.monocularPdNearLeft':                 {ids: ['#prescriptionLeftEyeNearInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.monocularPdNearRight':                {ids: ['#prescriptionRightEyeNearInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.opticalCenterHeightLeft':             {ids: ['#prescriptionLeftEyeOpticalCenterHeightInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.opticalCenterHeightRight':            {ids: ['#prescriptionRightEyeOpticalCenterHeightInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.opticalCenterMeasurementType':        {ids: ['#prescriptionOpticalCenterHeightStartingPointSelect'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.pantoscopicTilt':                     {ids: ['#prescription-pantoscopic-tilt'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.pupillaryDistanceType':               {ids: ['#prescriptionBinocularMonocularSelect'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.segmentHeightLeft':                   {ids: ['#prescriptionLeftEyeSegmentHeightInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.segmentHeightRight':                  {ids: ['#prescriptionRightEyeSegmentHeightInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.segmentMeasurementType':              {ids: ['#prescriptionSegmentHeightStartingPointSelect'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.sphereLeft':                          {ids: ['#prescriptionLeftEyeSphereInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.sphereRight':                         {ids: ['#prescriptionRightEyeSphereInput'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.thickness':                           {ids: ['#prescription-specified-thickness-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.thicknessTypeCode':                   {ids: ['#prescription-safetyThickness'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.verticalPrismLeft':                   {ids: ['#prescription-vertical-prism-left-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.verticalPrismRight':                  {ids: ['#prescription-vertical-prism-right-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensPrescription.verticalPrismDirLeft':                {ids: ['#vBase-Left'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.verticalPrismDirRight':               {ids: ['#vBase-Right'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensPrescription.wrapAngle':                           {ids: ['#prescription-wrap-angle'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensScratchCoating.code':                              {ids: ['#lens-scratch-coating-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensScratchCoating.description':                       {ids: ['#lens-scratch-coating-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensTintColor.code':                                   {ids: ['#lens-dye-color-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensTintColor.description':                            {ids: ['#lens-dye-not-listed-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensTintSample':                                       {ids: ['#lens-dye-details-toggle-group'], fieldType: FormFieldType.radioButton},
    'labOrderInformation.lensTintSamplePercentage':                             {ids: ['#lens-lighten-darken-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.lensTintType':                                         {ids: ['#lens-dye-type-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.lensUltraVioletCoating.code':                          {ids: ['#lens-UV-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.nosePads.code':                                        {ids: ['#lens-nose-pads-checkbox'], fieldType: FormFieldType.checkBox},
    'labOrderInformation.nosePads.description':                                 {ids: ['#lens-nose-pads-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.oneLens':                                              {ids: ['#lens-one-lens-dropdown'], fieldType: FormFieldType.dropDown},
    'labOrderInformation.pressOnPrism.code':                                    {ids: ['#lens-press-on-prism-checkbox'], fieldType: FormFieldType.checkBox},
    'labOrderInformation.pressOnPrism.description':                             {ids: ['#lens-press-on-prism-textbox'], fieldType: FormFieldType.textBox},
    'labOrderInformation.slabOff.code':                                         {ids: ['#lens-slab-off-checkbox'], fieldType: FormFieldType.checkBox},
    'labOrderInformation.slabOff.description':                                  {ids: ['#lens-slab-off-textbox'], fieldType: FormFieldType.textBox},
    'member.name.firstName':                                                    {ids: ['#insured-first-name'], fieldType: FormFieldType.textBox},
    'member.name.lastName':                                                     {ids: ['#insured-last-name'], fieldType: FormFieldType.textBox},
    'member.name.middle':                                                       {ids: ['#insured-middle-initial'], fieldType: FormFieldType.textBox},
    'patientConditions':                                                        {ids: ['#services-known-conditions-label'], fieldType: FormFieldType.noHighlighting},
    'patient.addresses[0].street1':                                             {ids: ['#patient-address1'], headerId: '#patient-address-label', fieldType: FormFieldType.textBox},
    'patient.addresses[0].city':                                                {ids: ['#patient-city-input'], headerId: '#patient-address-label', fieldType: FormFieldType.textBox},
    'patient.addresses[0].stateCode':                                           {ids: ['#patient-state-input'], headerId: '#patient-address-label', fieldType: FormFieldType.dropDown},
    'patient.addresses[0].zipCode':                                             {ids: ['#patient-zip-code-input'], headerId: '#patient-address-label', fieldType: FormFieldType.textBox},
    'patient.dateOfBirth':                                                      {ids: ['#patient-dob-input'], fieldType: FormFieldType.textBox},
    'patient.gender':                                                           {ids: ['#patient-sex-group'], fieldType: FormFieldType.radioButton},
    'patient.name.firstName':                                                   {ids: ['#date-of-service-name'], fieldType: FormFieldType.noHighlighting},
    'patient.name.lastName':                                                    {ids: ['#date-of-service-name'], fieldType: FormFieldType.noHighlighting},
    'patient.relationToSubscriber':                                             {ids: ['#patient-relationship-group'], fieldType: FormFieldType.radioButton},
    'patientPaymentAmount':                                                     {ids: ['#services-patient-paid-amount-input'], fieldType: FormFieldType.textBox},
    'patientSignature.indicator':                                               {ids: ['#patient-signature-checkbox'], fieldType: FormFieldType.checkBox},
    'patientSignature.dateSigned':                                              {ids: ['#patient-signature-date-input'], fieldType: FormFieldType.textBox},
    'priorAuthorizationNumber':                                                 {ids: ['#additional-information-service-verification-input'], fieldType: FormFieldType.textBox},
    'serviceLines[].unitCount':                                                 {ids: ['#service-line-?-unit-count-input'], fieldType: FormFieldType.textBox},
    'serviceLines[].cptHcpcsCode':                                              {ids: ['#service-line-?-hcpc-input'], fieldType: FormFieldType.textBox},
    'serviceLines[].billedAmount':                                              {ids: ['#service-line-?-billed-amount-input'], fieldType: FormFieldType.textBox},
    'serviceLines[].diagnosisPointers':                                         {ids: ['#service-line-?-diagnosis-pointer-0-input', '#service-line-?-diagnosis-pointer-1-input', '#service-line-?-diagnosis-pointer-2-input', '#service-line-?-diagnosis-pointer-3-input'], fieldType: FormFieldType.textBox},
    'serviceLines[].modifierCodes':                                             {ids: ['#service-line-?-mod-input'], fieldType: FormFieldType.textBox},
    'serviceLines[].otherInsuranceCoveredAmount':                               {ids: ['#service-line-?-other-insured-allowed-input'], fieldType: FormFieldType.textBox},
    'serviceLines[].otherInsuranceDeniedNotCoveredReason':                      {ids: ['#service-line-?-denied-or-paid-dropdown'], fieldType: FormFieldType.dropDown},
    'serviceLocation.physicalAddress.street1':                                  {ids: ['#facility-address1'], fieldType: FormFieldType.textBox},
    'referringDoctor.nationalProviderId':                                       {ids: ['#additional-information-referring-provider-npi-input'], fieldType: FormFieldType.textBox},
    'contactLens.necessaryContactLensPrescription':                             {ids: ['#prescriptionRightEyeSphereInput'], fieldType: FormFieldType.textBox}
  }

};

export enum ErrorTypes {
  // DEFAULT VALIDATORS
  Min = 'min',
  Max = 'max',
  Required = 'required',
  RequiredTrue = 'requiredTrue',
  Email = 'email',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Pattern = 'pattern',
  NullValidator = 'nullvalidator',
  Compose = 'compose',
  ComposeAsync = 'composeasync',
  // CUSTOM VALIDATORS
  NoFutureDate = 'noFutureDate',
  MinDate = 'minDate',
  MaxDate = 'maxDate',
  ZipCodeInvalid = 'zipCodeInvalid',
  AlphaNumeric = 'alphaNumeric',
  Name = 'name',
  MiddleInitial = 'middleInitial',
  PhoneInvalid = 'phoneInvalid',
  CheckboxRequired = 'checkboxRequired',
  ValueNotTrue = 'valueNotTrue',
  StateCodeInvalid = 'stateCodeInvalid',
  DropdownSelectionRequired = 'dropdownSelectionRequired',
  InvalidDateFormat = 'invalidDateFormat',
  InvalidDate = 'invalidDate',
  NoEmptyDiagnosisCodes = 'diagnosisCodesEmpty',
  InvalidDiagnosisCode = 'invalidDiagnosisCode',
  ServiceLinesAllOrNone = 'serviceLinesAllOrNone',
  ModifierCodes = 'modifierCodes',
  ModifierCodesLength = 'modifierCodesLength',
  FsaPaidPatientPaid = 'fsaPaidPatientPaid',
  PatientPaidTotalAmount = 'patientPaidTotalAmount',
  UnitCountsValidator = 'unitCountsValidator',
  CptHcpcsCode = 'cptHcpcsCode',
  DollarAmount = 'dollarAmount',
  NonZeroDollarAmount = 'nonZeroDollarAmount',
  ValidFirstCharacterForName = 'validFirstCharacterForName',
  OverZeroUnder100UpToOneDecimalPlaceValidator = 'overZeroUnder100UpToOneDecimalPlaceValidator', // specific to ed, b, and dbl values
  MaxDollarAmount = 'maxDollarAmount',
  OnlyOneHealthCoverageAllowed = 'onlyOneHealthCoverageAllowed',
  AtLeastOneHealthCoverage = 'atLeastOneHealthCoverage',
  InvalidMemberId = 'invalidMemberId',
  DeniedOrPaidReasonConditionallyRequired = 'deniedOrPaidReasonConditionallyRequired',
  IsRequiredCustomizableLens = 'isRequiredCustomizableLens',
  PrismDecimalAmount = 'prismDecimalAmount',
  OpticalCenterStartingPointRequiredWhenValuesPresent = 'opticalCenterStartingPointRequiredWhenValuesPresent',
}

export enum UserTypeQualifier {
  Patient = 'Patient',
  Member = 'Member',
  Insured = 'Insured',
  ReferringProvider = 'Referring Provider'
}

export enum HttpStatusCode {
  BadRequest = 400,
  NotFound = 404,
  Forbidden = 403,
  InternalServerError = 500,
  GatewayTimeOut = 504
}

export enum SessionStorageKeys {
  SessionTimestamp = 'sessionTimestamp',
  SessionActive = 'sessionActive',
  EligibilityRetrieveLink = 'eligibilityRetrieveLink',
  MemberRetrieveLink = 'memberRetrieveLink',
  AuthorizationSearchUrl = 'authorizationSearchUrl',
  AddDependentLink = 'addDependentLink',
  SubscriberClientId = 'subscriberClientId',
  SubscriberDivisionId = 'subscriberDivisionId',
  SubscriberConsumerId = 'subscriberConsumerId',
  PatientFirstName = 'patientFirstName',
  PatientLastName = 'patientLastName',
  PatientConsumerId = 'patientConsumerId',
  MemberDateOfService = 'memberDateOfService',
  CreateVsrLink = 'createVsrLink',
  RequestIsComingInFromPingFedLogInPage = 'requestIsComingInFromPingFedLogInPage',
  WindowUrlBeforeNavigatingToPingFedLogInPage = 'windowUrlBeforeNavigatingToPingFedLogInPage'
}

export enum ClaimStatus {
  SubmittedClaim = 'SUBMITTED_CLAIM',
  SavedClaim = 'SAVED',
  SubmittedLab = 'SUBMITTED_LAB'
}

export enum ClaimAction {
  Save = 'SAVE',
  Submit = 'SUBMIT',
  Calculate = 'CALCULATE'
}

export enum PrimitiveType {
  String = 'string',
  Number = 'number',
  Object = 'object',
  Boolean = 'boolean'
}

export enum OtherInsuranceReasonCode {
  NotCovered = 'Not_Covered',
  MaxAllowanceMet = 'Max_Allowance_Met',
  BundledService = 'Bundled_Service',
  TimelyFiling = 'Timely_Filing',
  Caption = 'Caption',
  Deductible = 'Deductable', // Typo is intentional here, 'Deductable' is in the domain model
  Not_Applicable = 'Not_Applicable'
}

export enum EyeSideEnum {
  Right = 'right',
  Left = 'left',
}

export enum MiscellaneousServices {
  PressOnPrismCode = 'SH',
  NosePadsCode = 'SS',
  SlabOffCode = 'ST'
}

export enum AuthorizedItemStatus {
  Available = 'AVAILABLE',      // Available to be used
  Authorized  = 'AUTHORIZED',   // Not yet used, but an auth has been created and the current user DOES have access to it
  Utilized  = 'UTILIZED',       // Used on a claim
  Unavailable  = 'UNAVAILABLE', // blocked by in lieu of rule
  Overridden  = 'OVERRIDDEN',   // Eligibility status has been overridden on the VSR & should be treated as available
  Excluded  = 'EXCLUDED',       // Excluded from the VSR
  Suspended  = 'SUSPENDED'      // This is to support legacy systems to transition to VSR
}
