import {FormStateYesNo} from '../../enum/form-state-yes-no';
import {ApplicationConstants} from '../../constants/application.constants';
import {AbstractControl} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {
  LoadingModalComponent,
  LoadingModalOptions
} from '../../components/loading-modal/loading-modal/loading-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MatRadioButton} from '@angular/material/radio';

export function isBoolean(value: any): boolean {
  return typeof value === 'boolean';
}

export function allStringArrayElementsEmpty(array: any[]): boolean {
  return array.every(element => isStringNullUndefinedOrEmpty(element));
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function isStringNullUndefinedOrEmpty(value: string): boolean {
  return isNullOrUndefined(value) || value.length === 0;
}

export function isStringNullUndefinedOrEmptyWithTrim(value: string): boolean {
  return isNullOrUndefined(value) || value.trim().length === 0;
}

export function isStringLiteralNullOrUndefined(value: string): boolean {
  return !isStringNullUndefinedOrEmptyWithTrim(value) && (value.trim().toLowerCase() === 'null' || value.trim().toLowerCase() === 'undefined');
}

export function isDollarAmountEmptyOrNull(value: string | number): boolean {
  return typeof (value) === 'string' ? isStringNullUndefinedOrEmpty(value) : isNullOrUndefined(value);
}

/**
 * Method formats a string in a once decimal format
 *
 * Example: If string 1.2223 is passed in, then it will return 1.2
 *
 * @param value - Value we want to format with only one decimal place
 */
export function oneDecimalPlaceFormatIfDecimalPresentInString(value: string): string {
  let processedValue: string = value;
  if (!isStringNullUndefinedOrEmpty(processedValue) && processedValue.includes(ApplicationConstants.period)) {
    const splitProcessedValue: string[] = processedValue.split(ApplicationConstants.period);
    processedValue = splitProcessedValue[0] + ApplicationConstants.period + splitProcessedValue[1].charAt(0);
  }
  return processedValue;
}

export function twoDecimalPlaceFormat(formControl: AbstractControl): void {
  if (!formControl) {
    return;
  }
  let resultingAmount = null;
  const amount = formControl.value;
  if (!Number.isNaN(Number(amount))) {
    const amountValue = (typeof formControl.value === 'number') ? formControl.value.toString() : formControl.value;
    if (!isStringNullUndefinedOrEmpty(amountValue)) {
      if (amountValue.indexOf('.') === -1) {
        resultingAmount = amountValue + '.00';
        formControl.patchValue(resultingAmount, ApplicationConstants.updateFormWithoutEmit);
      } else {
        const splitAmount = amountValue.split('.');
        if (!isStringNullUndefinedOrEmpty(splitAmount[1]) && splitAmount[1].length >= 2) {
          resultingAmount = splitAmount[0] + '.' + splitAmount[1].substring(0, 2);
          formControl.patchValue(resultingAmount, ApplicationConstants.updateFormWithoutEmit);
        } else if (!isStringNullUndefinedOrEmpty(splitAmount[1]) && splitAmount[1].length === 1) {
          resultingAmount = splitAmount[0] + '.' + splitAmount[1].charAt(0) + '0';
          formControl.patchValue(resultingAmount, ApplicationConstants.updateFormWithoutEmit);
        } else if (isStringNullUndefinedOrEmpty(splitAmount[1])) {
          resultingAmount = amountValue + '00';
          formControl.patchValue(resultingAmount, ApplicationConstants.updateFormWithoutEmit);
        }
      }
    }
  }
}

export function formatNumberWithTwoDecimalPlaces(numberToFormat: number): string {
  if (isNullOrUndefined(numberToFormat)) {
    return undefined;
  } else {
    const numberToFormatString: string = numberToFormat.toString();
    let formattedNumber: string;
    if (numberToFormatString.indexOf('.') === -1) {
      formattedNumber = numberToFormatString + '.00';
    } else {
      const splitAmount = numberToFormatString.split('.');
      if (!isStringNullUndefinedOrEmpty(splitAmount[1]) && splitAmount[1].length >= 2) {
        formattedNumber = splitAmount[0] + '.' + splitAmount[1].substring(0, 2);
      } else if (!isStringNullUndefinedOrEmpty(splitAmount[1]) && splitAmount[1].length === 1) {
        formattedNumber = splitAmount[0] + '.' + splitAmount[1].charAt(0) + '0';
      } else if (isStringNullUndefinedOrEmpty(splitAmount[1])) {
        formattedNumber = numberToFormatString + '00';
      }
    }
    return formattedNumber;
  }
}

export function toDollarAmount(value: string | Number) {
  let returnValue = '';
  if (isNullOrUndefined(value) || (typeof value === 'number' && isNaN(value))) {
    return;
  }
  value = value.toString();
  if (isStringNullUndefinedOrEmpty(value)) {
    return;
  }
  if (value.indexOf('.') === -1) {
    returnValue = value + '.00';
  } else {
    const splitValue = value.split('.');
    if (!isStringNullUndefinedOrEmpty(splitValue[1]) && splitValue[1].length > 1) {
      returnValue = splitValue[0] + '.' + splitValue[1].substring(0, 2);
    } else {
      returnValue = (splitValue[0] ? splitValue[0] : '') + '.' + splitValue[1] + '0'.repeat(2 - splitValue[1].length);
    }
  }
  return returnValue;
}

export function returnFormStateFromBoolean(booleanValue: boolean): string {
  return booleanValue ? FormStateYesNo.Yes : FormStateYesNo.No;
}

export function returnBooleanFromFormState(formStateValue: string | boolean): boolean {
  if (isNullOrUndefined(formStateValue)) {
    return undefined;
  }
  if (typeof formStateValue === 'boolean') {
    return formStateValue;
  } else {
    return formStateValue === FormStateYesNo.Yes || formStateValue === ApplicationConstants.yesSingleCharIndicator;
  }
}

/**
 * Returns a full name string
 * Returns in format: "{lastName}, {firstName} {middleInitial}"
 * @param {string} firstName
 * @param {string} middleInitial
 * @param {string} lastName
 * @returns {string}
 */
export function getFormattedFullName(firstName: string, lastName: string, middleInitial?: string): string {
  if (isStringNullUndefinedOrEmpty(firstName) && isStringNullUndefinedOrEmpty(middleInitial) && isStringNullUndefinedOrEmpty(lastName)) {
    return '';
  }
  firstName = (firstName) ? firstName.trim() : firstName;
  middleInitial = (middleInitial) ? middleInitial.trim() : middleInitial;
  lastName = (lastName) ? lastName.trim() : lastName;
  const firstNameAndMiddleInitial = [firstName, middleInitial].join(' ');
  const fullName = [lastName, firstNameAndMiddleInitial].join(', ');
  // trim any trailing spaces caused by missing properties
  return fullName.trim();
}

/**
 * Method formats the full name in a First Name Middle Initial(if it exists) and Last Name.
 *
 * @param firstName
 * @param lastName
 * @param middleInitial
 */
export function getFullNameFormattedInFirstMiddleAndLast(firstName: string, lastName: string, middleInitial?: string): string {
  if (isStringNullUndefinedOrEmptyWithTrim(firstName) && isStringNullUndefinedOrEmptyWithTrim(middleInitial) && isStringNullUndefinedOrEmptyWithTrim(lastName)) {
    return '';
  }
  firstName = (firstName) ? firstName.trim() : firstName;
  middleInitial = (middleInitial) ? middleInitial.trim() : middleInitial;
  lastName = (lastName) ? lastName.trim() : lastName;
  const firstNameAndMiddleInitial = (middleInitial) ? [firstName, middleInitial].join(ApplicationConstants.blankSpace) : firstName;
  const fullName = [firstNameAndMiddleInitial, lastName].join(ApplicationConstants.blankSpace);
  // trim any trailing spaces caused by missing properties
  return fullName.trim();
}

/**
 * Trims leading whitespace from the control value, given the form group and the control name.
 *
 * @param control
 */
export function trimWhitespaceFromControlValue(control: AbstractControl): void {
  // Exit if no control is found
  if (isNullOrUndefined(control)) {
    return;
  }

  // Parse and trim the control value
  const controlValue = control.value as string;
  if (!isStringNullUndefinedOrEmpty(controlValue) && new RegExp(/^\s|\s$/).test(controlValue)) {
    const trimmedControlValue = controlValue.replace(/^\s+|$/g, '');
    control.setValue(trimmedControlValue);
  }
}

/**
 * Slugify static string references to their ID counter part, will not remove special characters as this is simply for element IDs for now.
 *
 * e.g. 'Some Dropdown Value' -> 'some-dropdown-value'
 *
 * @param {string} value - string to slug
 * @returns {string} ID counterpart
 */
export function simpleSlugFromString(value: string): string {
  // Regex validators
  const wordDelimiters = new RegExp(/[\s—–_]/g);
  const invalidCharacters = new RegExp(/[^a-z0-9\-]/g);
  const multipleHyphens = new RegExp(/-{2,}/g);

  // Normalize the case
  value = value.toLowerCase();

  // Replace all word delimiters with hyphens
  value = value.replace(wordDelimiters, '-');

  // Remove invalid characters
  value = value.replace(invalidCharacters, '');

  // Replace multiple hyphens with single hyphen
  value = value.replace(multipleHyphens, '-');

  return value;
}

/**
 * Type safe setting of the input to null, or its corresponding value.
 *
 * @param valueToSetIfNotNull
 */
export function setToNullOrValue<T>(valueToSetIfNotNull: T): T | null {
  return isNullOrUndefined(valueToSetIfNotNull) ? null : valueToSetIfNotNull;
}

/**
 * Type safe setting of the input to undefined, or its corresponding value.
 *
 * @param valueToSetIfNotUndefined
 */
export function setToUndefinedOrValue<T>(valueToSetIfNotUndefined: T): T | undefined {
  if (typeof valueToSetIfNotUndefined === 'string') {
    return isStringNullUndefinedOrEmpty(valueToSetIfNotUndefined) ? undefined : valueToSetIfNotUndefined;
  } else {
    return isNullOrUndefined(valueToSetIfNotUndefined) ? undefined : valueToSetIfNotUndefined;
  }
}

/**
 * Type safe collection helper, contract: null/undefined => true.
 *
 * @param array
 */
export function isEmptyList<T>(array: T[]): boolean {
  return isNullOrUndefined(array) || array.length === 0;
}

/**
 * Converse convenience utility to isEmptyList, contract: null/undefined => false.
 *
 * @param array
 */
export function isNotEmptyList<T>(array: T[]): boolean {
  return !isEmptyList(array);
}

/**
 * Attempt to parse a string, contract: isNaN => undefined.
 *
 * @param numberToParse
 */
export function tryParseInt(numberToParse: string | number): number | undefined {
  if (typeof numberToParse === 'string') {
    return isNaN(parseInt(numberToParse)) ? undefined : parseInt(numberToParse);
  }

  return numberToParse;
}

/**
 * Wrapper for the built-in Angular decimal pipe that handles setting of the control value passed in.
 *
 * @param control
 * @param lowerBound
 * @param upperBound
 */
export function decimalPipeWrapper(control: AbstractControl, lowerBound?: number, upperBound?: number): void {
  // If number is not parsable, or does not contain all digits, return the thread
  if (isNaN(parseFloat(control.value)) || !(new RegExp(/^\d+$/).test(control.value))) {
    return;
  }

  // Format the value, return the thread if no transformed value is returned or the length is greater than 5, i.e. -12.00, 123.50
  const transformedValue: string | null = new DecimalPipe(ApplicationConstants.localeEnglishUS).transform(control.value, ApplicationConstants.twoDigitDecimalFormat);
  if (isStringNullUndefinedOrEmpty(transformedValue) || transformedValue.length > ApplicationConstants.rightLeftBaseCurveAndCustomizableLensFieldsMaxLength) {
    return;
  }

  control.setValue(transformedValue);
}

/**
 * @param apiUrl is the url expected to be used for the API call
 * @param domainFromPreference is the domain that might be set in the configuration and if it is, will be used to replace the domain of the apiUrl
 *
 * @return the url with the domain we have configured (or not) to use.
 */
export function getApiUrl(apiUrl: string, domainFromPreference: string): string {
  if (!isStringNullUndefinedOrEmpty(domainFromPreference)) {
    return apiUrl.replace(ApplicationConstants.domainOfUrlRegex, domainFromPreference);
  }
  return apiUrl;
}

/**
 * Method converts string value to boolean value if not it returns undefined.
 * @param trueFalseOrUndefinedString - String value we want to convert to boolean value.
 */
export function returnTrueFalseOrUndefinedFromStringValue(trueFalseOrUndefinedString: string): boolean | undefined {
  switch (trueFalseOrUndefinedString.toLowerCase()) {
    case ApplicationConstants.trueString: {
      return true;
    }
    case ApplicationConstants.falseString: {
      return false;
    }
    default: {
      return undefined;
    }
  }
}

/**
 * Method for Spinner (Preload Icon) with a Value (String)
 * @param loadingSpinnerValue - String value we want to display when Spinner loads.
 * @param dialog - MatDialog function
 */

export function openDialog(loadingSpinnerValue: string, dialog: MatDialog): void {

  dialog.open<LoadingModalComponent, LoadingModalOptions>(LoadingModalComponent,
    {
      data: {
        modalMessageText: `${loadingSpinnerValue}`
      },
      width: '250px',
      panelClass: 'loadingModal',
      disableClose: true
    });
}

export function fieldKeyPress(event: any, regex: RegExp): void {
  const inputChar = (event as KeyboardEvent).key;
  // if character typed in isn't allowed for prescription left/right fields, then ignore that character
  if (!regex.test(inputChar)) {
    event.preventDefault();
  }
}

// we will ignore drop events on the prescription left right fields that only allow numeric, period, plus, and minus characters, because the
// drop event does not contain the cursor position of where the new text will drop in at, it always uses the last cursor position possible
// which causes inconsistent behavior of where the text should drop when we are trying to filter out certain characeters liuke we can for paste
// and keypress.
export function ignoreDrop(event: any): void {
  // stop drop text from occurring
  event.stopPropagation();
  event.preventDefault();
}

export function fieldPaste(event: any, formControl: AbstractControl, maxLength: number, regex: RegExp): void {
  // stop paste propagation to outside paste handler
  event.stopPropagation();
  event.preventDefault();

  const newTextData = event.clipboardData;
  // if we are dealing with paste event, modify the paste text
  if (!isNullOrUndefined(newTextData)) {
    let pastedText = newTextData.getData('Text');

    // filter out all the non allowed characters from what was pasted into the field
    pastedText = (pastedText.match(regex) || []).join('');

    // merge the allowed characters of the newly pasted text with original text
    const fieldElement = event.target;
    const cursorPosStart = fieldElement.selectionStart;
    const cursorPosEnd = fieldElement.selectionEnd;
    const fieldValue = fieldElement.value;
    const textBeforeCursor = fieldValue.substring(0, cursorPosStart);
    const textAfterCursor = fieldValue.substring(cursorPosEnd, fieldValue.length);
    let mergedText = textBeforeCursor + pastedText + textAfterCursor;

    // if the merged text is more than the max number of characters allowed to be typed into the field, then chop off the tail end to meet the field max character length
    if (!isStringNullUndefinedOrEmpty(mergedText) && mergedText.length > maxLength) {
      mergedText = mergedText.substring(0, maxLength);
    }
    formControl.setValue(mergedText);
  }
}

// Checkbox should be checked or unchecked via Enter Key from keyboard
export function onKeypressEventCheckbox(control: AbstractControl): void {
    control.setValue(!control.value);
}

// Radiobutton should be checked or unchecked via Enter Key from keyboard
export function onKeypressEventRadiobutton(radioButton: MatRadioButton): void {
    radioButton.checked = true;
}

/**
 * Method validates that a value passed in is a a zero value.
 *
 * @param possibleZeroValue - Value we are evaluating is zero.
 */
export function isValueZero(possibleZeroValue: string | number): boolean {
  let isPassedInValueZero: boolean = false;
  if (!isNullOrUndefined(possibleZeroValue)) {
    let possibleZeroValueString: string = possibleZeroValue.toString().trim();
    if (possibleZeroValueString !== ApplicationConstants.emptyString
      && possibleZeroValueString !== ApplicationConstants.blankSpace
      && possibleZeroValueString !== ApplicationConstants.period
      && possibleZeroValueString !== ApplicationConstants.plusSign
      && possibleZeroValueString !== ApplicationConstants.minusSign
      && possibleZeroValueString.includes(ApplicationConstants.zeroString)) {

      if (possibleZeroValueString.includes(ApplicationConstants.zeroString)) {
        possibleZeroValueString = possibleZeroValueString.replace(/0/g, ApplicationConstants.emptyString);
      }
      if (possibleZeroValueString.includes(ApplicationConstants.period)) {
        possibleZeroValueString = possibleZeroValueString.replace(ApplicationConstants.period, ApplicationConstants.emptyString);
      }
      if (possibleZeroValueString.includes(ApplicationConstants.plusSign)) {
        possibleZeroValueString = possibleZeroValueString.replace(ApplicationConstants.plusSign, ApplicationConstants.emptyString);
      }
      if (possibleZeroValueString.includes(ApplicationConstants.minusSign)) {
        possibleZeroValueString = possibleZeroValueString.replace(ApplicationConstants.minusSign, ApplicationConstants.emptyString);
      }
      if (possibleZeroValueString.length === 0) {
        isPassedInValueZero = true;
      }
    }
  }
  return isPassedInValueZero;
}

/**
 * Function adds a delay to the code by a specified amount of milliseconds.
 *
 * @param milliseconds - Milliseconds we want to add a delay to the code.
 */
export function delay(milliseconds: number) {
  return new Promise(resolve => setTimeout( resolve, milliseconds));
}

export function convertToCamelCase(str: string): string {
  if (!isNullOrUndefined(str)) {
    str = str.toLowerCase();
    const arr = str.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(' ');
  }
  return str;
}
